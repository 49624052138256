<template>
  <div>
    <Title :title="title" class="text-center"></Title>
    <v-data-table
      :headers="headers"
      :items="tableData.data"
      :loading="loading"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      :page.sync="tableData.current_page"
      hide-default-footer
      @click:row="
        (item, row) => {
          $emit('RowClick', { item: item, row: row });
        }
      "
    >
    <template v-slot:[`item.id`]="{ item }">
        <p v-if="item.blocked"
        >
        <v-icon color="red">mdi-cancel</v-icon> 
          {{ item.id  }}
        </p>
        <p v-else>{{ item.id  }}</p>
      </template>
      <template v-slot:[`item.response`]="{ item }">
        <v-chip
          class="ma-2"
          :color="item.response == 'VERIFIED' ? 'green' : 'red'"
          text-color="white"
        >
          {{ item.response == null ? "UNVERIFIED" : item.response }}
        </v-chip>
      </template>
      <template v-slot:[`item.status_response`]="{ item }">
        <v-chip
          class="ma-2"
          :color="item.status == 'VERIFIED' ? 'green' : 'red'"
          text-color="white"
        >
          {{ item.status == null ? "UNVERIFIED" : item.status }}
        </v-chip>
      </template>

      <template v-slot:[`item.calculated`]="{ item }">
        <v-chip
          class="ma-2"
          :color="item.calculated == 1 ? 'green' : 'red'"
          text-color="white"
        >
          {{ item.calculated == 0 ? "UNDONE" : "DONE" }}
        </v-chip>
      </template>

      <template v-slot:[`item.error_response`]="{ item }">
        <v-chip class="ma-2" color="warning" text-color="black">
          {{ item.error_response }}
        </v-chip>
      </template>
      <template v-slot:[`item.friend.error_response`]="{ item }">
        <v-chip class="ma-2" color="warning" text-color="black">
          {{ item.friend ? item.friend.error_response : "Nothing" }}
        </v-chip>
      </template>

      <template v-slot:[`item.status_order`]="{ item }">
        <v-chip
          class="ma-2"
          style="border-radius: 5px"
          :color="item.color ? item.color  :
            item.status == 'قيد معالجة الطلب'
              ? '#0088a8'
              : item.status == 'تمت العملية بنجاح'
              ? 'green'
              : 'red'
          "
          text-color="white"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.money.status`]="{ item }">
        <v-chip
          class="ma-2"
          style="border-radius: 5px"
          :color="
          item.money.color ? item.money.color  : 'gray'
          "
          text-color="white"
        >
          {{ item.money.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          class="ma-2"
          :color="
            item.status == 0 ? 'red' : item.status == 1 ? 'green' : '#0088a8'
          "
          text-color="white"
        >
          {{
            item.status == 1
              ? "DONE"
              : item.status == 0
              ? "UNDONE"
              : item.status
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <v-chip class="ma-2">
          {{ item.phone }}
        </v-chip>
      </template>
      <template v-slot:[`item.bank_result`]="{ item }">
        <v-chip class="ma-2" color="#ffd2a3" text-color="dark">
          {{ bankResult(item.result) }}
        </v-chip>
      </template>

      <template v-slot:[`item.orders_count`]="{ item }">
        <v-chip
          class="ma-2"
          :color="item.orders_count != 0 ? 'green' : 'red'"
          text-color="white"
        >
          {{ item.orders_count }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at, item.on_time) }}
      </template>

      <template v-slot:[`item.area`]="{ item }"
        >{{ item.area }} {{ item.area_unit.name_ar }}
      </template>
      <template v-slot:[`item.customer_id`]="{ item }">
        <router-link target="_blank" :to="'/customer/' + item.customer_id">{{
          item.customer_id
        }}</router-link>
      </template>
      <template v-slot:[`item.currency_price`]="{ item }"
        >{{ item[`${item.currency}`] + " " + item.currency }}
      </template>
      <template v-slot:[`item.type_phone`]="{ item }"
        >{{ item.type_id == 1 ? "وحدات" : "فاتورة" }}
      </template>

      <template v-slot:[`item.currency_tag`]="{ item }"
        >
        <div style="display:flex">
        <v-chip v-for="(currency_item,i) in item.currency" :key="i" color="primary" style="display: inline; margin-right: 2px;">{{ currency_item }}</v-chip></div>
      </template>

      <template v-slot:[`item.EndID_tag`]="{ item }" 
        >
        <div style="display:flex">
        <v-chip v-for="(EndID_item,i) in item.EndID" :key="i" color="success" style="display: inline; margin-right: 2px;">{{ EndID_item }}</v-chip></div>
      </template>

      <template v-slot:[`item.type_tag`]="{ item }" 
        >
        <div style="display:flex">
        <v-chip v-for="(type_item,i) in item.type" :key="i" color="purple" style="display: inline; margin-right: 2px;" dark>{{ type_item }}</v-chip></div>
      </template>

      <template v-slot:[`item.secret`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            {{ item.secret.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.secret }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.client`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            {{ item.client.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.client }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.duration_paypal`]="{ item }">
      <div>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.duration_paypal.split(" | ")[0].substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.duration_paypal.split(" | ")[0] }}
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.duration_paypal.split(" | ")[1].substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.duration_paypal.split(" | ")[1] }}
      </v-tooltip>
      </div>
      
    </template>


    <template v-slot:[`item.duration_wise`]="{ item }">
      <div>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.duration_wise.split(" | ")[0].substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.duration_wise.split(" | ")[0] }}
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.duration_wise.split(" | ")[1].substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.duration_wise.split(" | ")[1] }}
      </v-tooltip>
      </div>
      
    </template>

    <template v-slot:[`item.details_en`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.details_en.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.details_en }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.details_ar`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.details_ar.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.details_ar }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.details_en`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.details_en.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.details_en }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.price_now`]="{ item }">
          
        {{ item.price_now + ' '+ item.currency }}
    </template>
    

    <template v-slot:[`item.details_bank_ar`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.details_bank_ar.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.details_bank_ar }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.details_bank_en`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" >
            {{ item.details_bank_en.substring(0, 25) + "..." }}
          </div>
        </template>
        {{ item.details_bank_en }}
      </v-tooltip>
    </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          class="ma-2"
          :color="
            item.type == 0
              ? 'orange'
              : item.type == 1
              ? 'orange'
              : item.type == 2
              ? 'green'
              : 'gray'
          "
          text-color="white"
        >
          {{
            item.type == 0
              ? "Deposit"
              : item.type == 1
              ? "Withdraw"
              : item.type == 2
              ? "Profit"
              : item.type
          }}
        </v-chip>
      </template>

      <template v-slot:top>
        <div>
          <v-toolbar elevation="3">
            <!-- <v-toolbar-title>{{title}}</v-toolbar-title> -->
            <!--<Search
                          class="mx-4"
                          @search="
                            (val) => {
                              search = val;
                            }
                          "
                        ></Search>-->

            <v-text-field
              v-model="search"
              class="mx-4"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <div v-if="create" @click="openForm()">
              <Button
                v-if="newItemLabel"
                :label="`New ${newItemLabel}`"
                color="primary"
              >
              </Button>
            </div>
          </v-toolbar>
          <Filters
            :search="search"
            :api="api"
            :page="current_page"
            :per_page="per_page"
            :items="tableData.data"
            :filter="filter"
          ></Filters>
        </div>
      </template>

      <template
        v-for="(slot, i) in slots"
        v-slot:[`item.${slot.value}`]="{ item }"
      >
        <div v-if="isSlots" :key="i">
          <div v-if="i == 0">{{ item[slot.value] }}</div>
          <div v-else>
            <v-icon v-if="item[slot.value] == 1" color="primary" small>
              mdi-key-plus
            </v-icon>
            <v-icon v-else color="error" small> mdi-key-remove</v-icon>
          </div>
        </div>
      </template>

      <template v-slot:[`item.operation`]="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }" v-if="resend">
              <v-btn small color="error" v-on="on"> operation</v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="resend"
                @click="
                  () => {
                    color = 'red';
                    title = 'Are You sure You want Resend To This customer ?';
                    item.opoperation = 'resend';
                    openOrderDialog(item);
                  }
                "
              >
                <v-list-item-title> resend </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-update</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                v-if="message"
                @click="
                  () => {
                    color = 'blue';
                    title =
                      'You Will Send Message And Notification To This Customer Is That is Okay ?';
                    item.opoperation = 'message';
                    openOrderDialog(item);
                  }
                "
              >
                <v-list-item-title> message </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-message</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                v-if="exchange_sim"
                @click="
                  () => {
                    title = 'You Will Change Type Of This SIM  ?';
                    color = 'green';
                    item.opoperation = 'exchange_sim';
                    openOrderDialog(item);
                  }
                "
              >
                <v-list-item-title> exchange sim </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-repeat</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <!-- <v-list-item v-if="del" @click="openDeleteDialog(item)">
                <v-list-item-title>delete </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item> -->
             
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template
              v-slot:activator="{ on }"
              v-if="edit || del || show || pay"
            >
              <v-btn small dark color="primary" v-on="on"> Actions</v-btn>
            </template>
            <v-list>
              <v-list-item v-if="edit" @click="openForm(item)">
                <v-list-item-title> edit </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-pencil</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item v-if="download" @click="openUrl(item)">
                <v-list-item-title>download </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-file-document-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-if="inventory"
                v-show="!item.calculated"
                @click="openInventoryDialog(item)"
              >
                <v-list-item-title>Inventory </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-calculator-variant-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                v-if="edit_rate_to_sy"
                @click="openEditRateToSyDialog(item)"
              >
                <v-list-item-title>update Edit Rate To Sy </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-calculator-variant-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="del" @click="openDeleteDialog(item)">
                <v-list-item-title>{{ titleDeleteButton }} </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-if="show"
                @click="$router.push(api.show + item.id)"
              >
                <v-list-item-title>show </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-eye</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item v-if="pay" @click="openPayDialog(item)">
                <v-list-item-title>Make as Payed </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small> mdi-cart</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item v-if="block" @click="
                  () => {
                    color = 'purple';
                    title = 'Are You sure You want Block To This Order ?';
                    item.opoperation = 'block';
                    openOrderDialog(item);
                  }
                ">
                <v-list-item-title>block </v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-cancel</v-icon>
                </v-list-item-icon>
                </v-list-item>
              <v-list-item v-if="recovery_update_order" @click="openRecoveryDialog(item)">
                <v-list-item-title>{{ titleRecoveryButton }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-card class="mt-3 align-left">
      <v-row wrap>
        <v-col cols="12" md="6">
          <v-pagination
            total-visible="6"
            :value="parseInt(current_page)"
            :model="parseInt(current_page)"
            color="#2170B4"
            :length="tableData.last_page"
            prev-icon="mdi-arrow-left"
            next-icon="mdi-arrow-right"
            @input="updatePage"
          >
          </v-pagination>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="current_page"
            label="Page"
            hide-details
            @input="updatePage"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <DialogAlert
      :title="titleDelete"
      :isPopup="dialogDelete"
      :page="parseInt(current_page)"
      :model="model"
      @confirmDialog="deleteItemConfirm"
    ></DialogAlert>

    <DialogAlert
        :title="titleRecovery"
        :isPopup="dialogRecovery"
        :page="parseInt(current_page)"
        :model="modelRecovery"
        @confirmDialog="recoveryItemConfirm"
    ></DialogAlert>

    <DialogInventory
      :title="inventoryTitle"
      :isPopup="dialogInventory"
      :page="parseInt(current_page)"
      :model="inventoryModel"
      @confirmDialog="inventoryItemConfirm"
    ></DialogInventory>

    <DialogEditRateToSy
      :title="editRateToSyTitle"
      :isPopup="dialogEditRateToSy"
      :page="parseInt(current_page)"
      :model="editRateToSyModel"
      @confirmDialog="editRateToSyItemConfirm"
    ></DialogEditRateToSy>

    <DialogAlert
      :title="title"
      :color="color"
      :isPopup="dialogOrder"
      :model="model"
      :page="parseInt(current_page)"
      @confirmDialog="resendItemConfirm"
    ></DialogAlert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    headers: Array,
    title: String,
    titleDelete: {
      type: String,
      default: "Are you sure you want to delete this item",
    },
    titleRecovery: {
      type: String,
      default: "Are you sure you want to Refund this item",
    },
    inventoryTitle: {
      type: String,
      default: "Inventory Title",
    },
    titleDeleteButton: {
      type: String,
      default: "Delete",
    },
    titleRecoveryButton: {
      type: String,
      default: "Refund",
    },
    api: Object,
    filter: {
      type: String,
      default: "",
    },
    queryParam: {
      default: "",
    },
    newItemLabel: {
      default: "item",
    },
    route_name: {
      default: "",
    },
    create: {
      default: true,
    },
    block: {
      default: false,
    },
    edit: {
      default: true,
    },
    del: {
      default: true,
    },
    download: {
      default: false,
    },
    inventory: {
      default: false,
    },
    recovery_update_order: {
      default: false,
    },
    edit_rate_to_sy: {
      default: false,
    },
    show: {
      default: false,
    },
    isSlots: {
      default: false,
    },
    subscribe: {
      default: false,
    },
    resend: {
      default: false,
    },
    message: {
      default: false,
    },
    exchange_sim: {
      default: false,
    },
    pay: {
      default: false,
    },
    TypeInventory: {
      default: "Money",
    },
  },
  data: () => ({
    editRateToSyTitle: "black",
    color: "black",
    current_page: 1,
    model: {},
    modelRecovery: {},
    inventoryModel: {},
    editRateToSyModel: {},
    slots: [],
    search: null,
    dialog: false,
    dialogOrder: false,
    dialogEditRateToSy: false,
    dialogInventory: false,
    dialogDelete: false,
    dialogRecovery: false,
    tempData: [],
    per_page: 10,
  }),
  computed: {
    ...mapGetters(["getTableData"]),
    ...mapGetters(["getLoading"]),
    loading() {
      return this.getLoading;
    },
    tableData() {
      return this.getTableData;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogRecovery(val) {
      val || this.closeRecovery();
    },
    per_page() {
      this.getData();
    },
    search(val) {
      if (val == null) {
        this.getData;
      }
    },
  },
  methods: {
    ...mapActions(["fetchTableData", "deleteItem", "setForm", "sendFormOrder"]),
    updatePage(val) {
      this.current_page = val;
      this.$emit("update_page", parseInt(val));

      this.getData(val);
    },
    getData(page = 1) {
      this.tempData = this.tableData;

      this.fetchTableData({
        api: this.api,
        per_page: this.per_page,
        page: page,
      });

    },
    formatDate(val, on_time) {
      var data = new Date(val);
      var string =
        data.getUTCFullYear() +
        "/" +
        (data.getUTCMonth() + 1) +
        "/" +
        data.getDate();

      if (on_time == null) {
        string +=
          " " +
          data.getHours() +
          ":" +
          data.getMinutes() +
          ":" +
          data.getSeconds();
      }
      return string;
    },
    changePagination(val) {
      this.getData();
    },

    openForm(item = null) {
      this.$emit("openForm", item);
    },
    openPayDialog(item) {
      this.$emit("openFormPay", item);
    },

    openDeleteDialog(item) {
      this.model = item;
      this.dialogDelete = !this.dialogDelete;
    },
    openRecoveryDialog(item) {
      this.modelRecovery = item;
      this.dialogRecovery = !this.dialogRecovery;
    },
    openInventoryDialog(item) {
      this.inventoryModel = item;
      this.dialogInventory = !this.dialogInventory;
    },
    openEditRateToSyDialog(item) {
      this.editRateToSyModel = item;
      this.editRateToSyTitle = item.name;
      this.dialogEditRateToSy = !this.dialogEditRateToSy;
    },
    openUrl(item) {
      window.open(this.api.download + item.id);
    },
    openOrderDialog(item) {
      this.model = item;
      this.dialogOrder = !this.dialogOrder;
    },
    deleteItemConfirm() {
      this.deleteItem({
        api: this.api,
        id: this.model.id, // item id
        page: this.current_page,
      });
    },
    recoveryItemConfirm() {

      this.$store.dispatch("sendFormUnLimited", {
        api: this.api.recovery_update_order,
        form: {id : this.modelRecovery.id},
        isNew: false,
      });
      this.getData(this.page);

    },
    inventoryItemConfirm(USDRateToSp) {
      var form = {
        order: this.inventoryModel,
        usd_rate_to_sp: USDRateToSp,
        Type: this.TypeInventory,
      };

      this.$store.dispatch("sendFormUnLimited", {
        api: this.api.inventory,
        form: form,
        isNew: true,
      });
      this.getData(this.page);
    },

    editRateToSyItemConfirm(USDRateToSp) {
      var form = {
        id: this.editRateToSyModel.id,
        new_rate_to_sp: USDRateToSp,
      };

      this.$store.dispatch("sendFormUnLimited", {
        api: this.api.editRateToSy,
        form: form,
        isNew: true,
      });
      this.getData(this.page);
    },

    resendItemConfirm() {
      if (this.model.opoperation == "resend" && this.model.response == null) {
        this.model.response = "VERIFIED";
      }
      else if (this.model.opoperation == "block"){
        this.model.type = this.api.type
      }
      this.sendFormOrder({
        api: this.api,
        form: this.model, // item id
        opoperation: this.model.opoperation,
        page: this.current_page,
      });
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeRecovery() {
      this.dialogRecovery = false;
    },
    bankResult(val) {
      if (val) {
        val = JSON.parse(val);
        return val.errorDescriptionAr;
      }
      return "Nothing";
    },
  },
  async mounted() {
    await this.getData();
    if (this.isSlots) {
      this.slots = this.headers;
    }
  },
};
</script>
