<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="Transfer Money Orders"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :edit="edit"
          :pay="pay"
          :headers="headers"
          @openForm="setForm"
          @openFormPay="setFormPay"
          @update_page="
            (val) => {
              page = val;
            }
          "
        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_pay_form" max-width="450px">
      <ConfirmMoneyForm
        v-if="dialog_pay_form"
        newItemLabel="Confirm Paying"
        :isNew="isNew"
        :api="api"
        :headers="headers"
        :page="page"
        @dialogForm="dialog_pay_form = false"
      ></ConfirmMoneyForm>
    </v-dialog>

    <v-dialog v-model="dialog_form" max-width="500px">
      <money-edit-form
        v-if="dialog_form"
        :fields="fields"
        newItemLabel="Add Notes"
        :isNew="isNew"
        :page="page"
        :api="api"
        @dialogForm="dialog_form = false"
      ></money-edit-form>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      dialog_pay_form: false,
      page: 1,
      api: {
        getAll: "transfer/inProgress",
        pay: "transfer/make_as_payed",
        edit: "transfer/update",
        create:'transfer/calc_haram'
      },
      fields: [
        {
          name: "notes",
          key: "notes",
        },
      ],
      pay: true,
      create: false,
      del: false,
      edit: true,
      filter: "Orderss",
      title: "transfer money",

      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "amount",
          value: "amount",
        },
        {
          text: "amount_sy",
          value: "amount_sy",
        },
        {
          text: "currency",
          value: "currency",
        },
        {
          text: "status",
          value: "status_order",
        },
        {
          text: "response",
          value: "response_relation.result",
        },
        {
          text: "payment_method",
          value: "payment_method",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "beneficiary_name",
          value: "beneficiary_name",
        },
        {
          text: "company_name",
          value: "company_name",
        },
        {
          text: "date",
          value: "created_at",
        }
      ],
    };
  },
  watch: {
    dialog_form: {
      handler(newVal) {
        if (!newVal) {
          this.$store.dispatch("EmptyAmount");
        }
      },
    },
  },
  methods: {
    setForm(val) {
      let form = {
        id: null,
        amount: null,
        amount_sy: null,
        beneficiary_no: null,
        beneficiary_name: null,
        payment_method: null,
        status: null,
        color:null,
        notes: null,
        currency: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
    setFormPay(val) {
      let form = {
        id: null,
        customer_id:null,
        amount:null,
        amount_sy:null,
        currency:null,
        status:null,
        response:null,
        payment_method:null,
        beneficiary_no:null,
        beneficiary_name:null,
        company_name:null,
        notes:null,
        created_at:null
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_pay_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>
